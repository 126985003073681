var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5",attrs:{"id":"aTravesDeLaNutricion"}},[_c('div',{ref:"los-alimentos-como-la-base-del-sistema-inmunitario",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.1.a.titulo'),"text":_vm.$t('articulos.1.a.texto'),"fuenteImg":"SAN.png","fuente":_vm.$t('articulos.1.a.fuente'),"img":"","productos":[
        'sustenta-neutro',
        'sustenta-vainilla',
        'sustenta-fibras',
        'sustenta-db-neutro',
        'sustenta-db-vainilla' ]}})],1),_c('div',{ref:"azucar-en-sangre-los-alimentos-aliados",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.1.b.titulo'),"text":_vm.$t('articulos.1.b.texto'),"fuenteImg":"SAN.png","fuente":_vm.$t('articulos.1.b.fuente'),"img":"","productos":['sustenta-db-neutro', 'sustenta-db-vainilla'],"customSettings":{itemsToShow: 2, centerMode: false,  pagination: 'fraction', trimWhiteSpace: false, itemsToSlide: 0},"shrinkRecomendados":true}})],1),_c('div',{ref:"preparacion-y-recuperacion-de-cirugias",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.1.c.titulo'),"text":_vm.$t('articulos.1.c.texto'),"img":"","productos":[
        'sustenta-neutro',
        'sustenta-vainilla',
        'sustenta-fibras',
        'sustenta-db-neutro',
        'sustenta-db-vainilla',
        'poli-carb',
        'lactoproteyn' ]}})],1),_c('div',{ref:"desnutricion",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.1.d.titulo'),"text":_vm.$t('articulos.1.d.texto'),"img":"rueda.png","productos":[
        'sustenta-neutro',
        'sustenta-vainilla',
        'sustenta-fibras',
        'sustenta-db-neutro',
        'sustenta-db-vainilla',
        'sustenta-semielemental'
      ]}})],1),_c('div',{ref:"alergia-a-la-proteína-de-leche-e-intolerancia-a-la-lactosa",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.1.e.titulo'),"text":_vm.$t('articulos.1.e.texto'),"img":"","productos":[
        'sustenta-neutro',
        'sustenta-vainilla',
        'sustenta-semielemental',
        'sustenta-junior-semielemental',
        'sustenta-junior-sin-lactosa' ]}})],1),_c('div',{ref:"epilepsia-refractaria",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.1.f.titulo'),"text":_vm.$t('articulos.1.f.texto'),"img":"","productos":[
        'dieta-cetogenica'
      ],"customSettings":{itemsToShow: 3, centerMode: false,  pagination: 3, trimWhiteSpace: false, itemsToSlide: 0}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }