<template>
  <div class="mt-5" id="aTravesDeLaNutricion">
    <div
      class="container"
      ref="los-alimentos-como-la-base-del-sistema-inmunitario"
    >
      <Articulo
        :title="$t('articulos.1.a.titulo')"
        :text="$t('articulos.1.a.texto')"
        fuenteImg="SAN.png"
        :fuente="$t('articulos.1.a.fuente')"
        img=""
        :productos="[
          'sustenta-neutro',
          'sustenta-vainilla',
          'sustenta-fibras',
          'sustenta-db-neutro',
          'sustenta-db-vainilla',
        ]"
      />
    </div>
    <div class="container" ref="azucar-en-sangre-los-alimentos-aliados">
      <Articulo
        :title="$t('articulos.1.b.titulo')"
        :text="$t('articulos.1.b.texto')"
        fuenteImg="SAN.png"
        :fuente="$t('articulos.1.b.fuente')"
        img=""
        :productos="['sustenta-db-neutro', 'sustenta-db-vainilla']"
        :customSettings="{itemsToShow: 2, centerMode: false,  pagination: 'fraction', trimWhiteSpace: false, itemsToSlide: 0}"
        :shrinkRecomendados="true"
      />
    </div>
    <div class="container" ref="preparacion-y-recuperacion-de-cirugias">
      <Articulo
        :title="$t('articulos.1.c.titulo')"
        :text="$t('articulos.1.c.texto')"
        img=""
        :productos="[
          'sustenta-neutro',
          'sustenta-vainilla',
          'sustenta-fibras',
          'sustenta-db-neutro',
          'sustenta-db-vainilla',
          'poli-carb',
          'lactoproteyn',
        ]"
      />
    </div>
    <div class="container" ref="desnutricion">
      <Articulo
        :title="$t('articulos.1.d.titulo')"
        :text="$t('articulos.1.d.texto')"
        img="rueda.png"
        :productos="[
          'sustenta-neutro',
          'sustenta-vainilla',
          'sustenta-fibras',
          'sustenta-db-neutro',
          'sustenta-db-vainilla',
          'sustenta-semielemental'
        ]"
      />
    </div>
    <div class="container" ref="alergia-a-la-proteína-de-leche-e-intolerancia-a-la-lactosa">
      <Articulo
        :title="$t('articulos.1.e.titulo')"
        :text="$t('articulos.1.e.texto')"
        img=""
        :productos="[
          'sustenta-neutro',
          'sustenta-vainilla',
          'sustenta-semielemental',
          'sustenta-junior-semielemental',
          'sustenta-junior-sin-lactosa',
        ]"
      />
    </div>
    <div class="container" ref="epilepsia-refractaria">
      <Articulo
        :title="$t('articulos.1.f.titulo')"
        :text="$t('articulos.1.f.texto')"
        img=""
        :productos="[
          'dieta-cetogenica'
        ]"
        :customSettings="{itemsToShow: 3, centerMode: false,  pagination: 3, trimWhiteSpace: false, itemsToSlide: 0}"
      />
    </div>
  </div>
</template>

<script>
import Articulo from "@/components/Articulo.vue";
export default {
  name: "ATravesDeLaNutricion",
  components: {
    Articulo,
  },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        this.scrollToAnchorPoint(refName);
      }
    });
  },
  methods: {
    getImage(fileName) {
      return require("../../assets/images/carousel/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>